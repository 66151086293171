import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HealthyHeartsLayout from '../../components/healthy-hearts/HealthyHeartsLayout.jsx';

import { connect } from 'react-redux';
import { HEALTHY_HEARTS_NAVIGATION } from '../../state/actionTypes.js';

class Diet extends React.Component {
    constructor(props){
        super(props);
        props.healthyHeartsNavigation(4);
    }

    render() {
        return (
            <Layout>
                <HealthyHeartsLayout>
                    <div className="HealthyHeartsMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">It's common sense &ndash; for dogs and people &ndash; that a healthy, balanced diet is good for the heart. It's also common sense that an excessive, unbalanced diet is bad for the heart.</p>
                                <p className="SectionText">Your dog's diet needs to be complete and balanced, and made up of high quality ingredients that meet its nutritional needs. These needs can change depending on your dog's age and state of health.</p>
                                <p className="SectionText">Talk to your vet about how much and what type of food you should feed your dog.</p>
                            </div>
                        </div>
                    </div>
                </HealthyHeartsLayout>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        healthyHeartsNavigation: healthyHeartsPage => dispatch({
            type: HEALTHY_HEARTS_NAVIGATION,
            healthyHeartsPage,
        })
    }
}

export default connect(null, mapDispatchToProps)(Diet);
